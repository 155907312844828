body {
  margin: 0;

  font-family: "Antic";
  font-size: 12px;
}

.app {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr 100px;
  grid-template-areas:
    "topbar"
    "xcontent"
    "footer";
}

.header {
  grid-area: topbar;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 1fr 1fr;

  .banner {
    align-self: left;
    height: 100%;
  }

  .logo {
    align-self: center;
    justify-self: right;
    max-width: 150px;
    padding-right: 10px;
  }
}

.footer {
  justify-self: right;
  grid-area: footer;

  margin: 20px 0 20px 0;
  padding: 10px 0 0 5px;

  width: 200px;
  height: 75px;

  color: white;
  background-color: #96293b;
  border-radius: 10px 0 0 10px;
  line-height: 20px;

  .title {
    font-weight: bold;
  }

  p {
    margin: 3px;
    font-size: 13px;
    letter-spacing: 1px;

    a {
      color: white;
      text-decoration: none;

      :visited {
        color: white;
      }
    }
    i.fa-whatsapp {
      background-color: #00e676;
      border-radius: 100px;
    }
  }
}

.home {
  grid-area: xcontent;
  justify-self: center;

  h2 {
    text-align: center;
    color: #96293b;
  }

  .empresas {
    max-width: 300px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;

    justify-items: center;

    padding-bottom: 20px;

    .btn-empresa {
      width: 130px;
      height: 85px;

      background: #ffffff;
      box-shadow: 6px 6px 11px rgba(0, 0, 0, 0.06),
        -6px -6px 11px rgba(0, 0, 0, 0.06);
      border-radius: 10px;

      img {
        padding: 5px;
      }
    }
  }
}

.return {
  padding: 10px;
  a {
    font-size: 14px;
    line-height: 18px;
    color: #a61929;
    text-decoration: none;
    img {
      width: 20px;
      padding-right: 5px;
    }
  }
}

.logout {
  padding: 10px;
  a {
    float: right;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    color: #a61929;
    text-decoration: none;
    img {
      width: 15px;
      padding-left: 5px;
    }
  }
}

.login {
  grid-area: xcontent;
  display: grid;
  padding-bottom: 100px;
  .login-form {
    justify-self: center;

    display: grid;
    justify-items: center;

    max-width: 300px;
    text-align: center;

    img {
      margin: 50px 0 20px 0;
    }

    .login-error {
      color: red;
      font-weight: bold;
      padding: 10px 0;
    }

    input[type="number"] {
      text-align: center;
      width: 100%;
      min-width: 200px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      background: #c4c4c4;
      border: none;
      border-radius: 10px;
      margin-bottom: 5px;
      padding: 6px 0;
      &::placeholder {
        font-size: 10px;
        font-weight: lighter;
        color: rgba(255, 255, 255, 0.733);
      }
    }

    button {
      font-family: "Antic";
      font-size: 13px;
      letter-spacing: 0.1em;
      padding: 3px 0;
      width: 100%;
      color: white;
      background: #96293b;
      border-radius: 10px;
    }
  }
}

.corredores {
  grid-area: xcontent;
  display: grid;

  padding-bottom: 100px;

  .corredores-logo {
    justify-self: center;

    display: grid;
    justify-items: center;

    max-width: 300px;
    text-align: center;

    img {
      margin: 50px 0 20px 0;
    }
  }

  .corredores-lista {
    justify-self: center;
    width: 250px;

    .label {
      display: inline-block;
      width: 100%;
      text-align: left;
      padding-bottom: 5px;
    }

    .label-c {
      color: #96293b;
    }

    .label-i {
      color: #535353;
    }

    .corredor {
      display: block;
      width: 100%;
      text-align: center;
      text-decoration: none;

      background: #ffffff;

      box-sizing: border-box;
      border-radius: 5px;
      padding: 4px;

      letter-spacing: 0.1em;
    }

    .colaborador {
      border: 1px dashed #96293b;
      color: #96293b;
    }

    .invitado {
      border: 1px dashed #535353;
      color: #535353;
      margin-bottom: 10px;
    }

    .folio {
      border: 1px solid;
      text-align: left;
      letter-spacing: 0;
      font-size: 10px;

      padding: 6px;
      i {
        color: black;
        font-size: 14px;
        float: right;
      }
    }
  }
}

.registro {
  grid-area: xcontent;
  display: grid;

  padding-bottom: 100px;

  .registro-title {
    font-size: 20px;
    color: #96293b;
    padding-left: 20px;
    padding-bottom: 30px;
  }

  .registro-form {
    justify-self: center;
    width: 250px;

    label {
      display: block;
      color: #96293b;
      width: 100%;
      padding: 5px 0;
    }

    input {
      width: 100%;
      border: 1px solid #96293b;
      border-radius: 5px;
      font-size: 12px;
      text-align: center;
      padding: 3px 0;
    }

    select {
      color: 96293b;
      margin-right: 5px;
      border-radius: 5px;
      border: 1px solid #96293b;
    }

    .full-width {
      width: 100%;
    }

    select::selection {
      color: white;
      background: black;
    }

    .fecha_nacimiento {
      display: inline-block;
      width: 160px;
    }

    .genero {
      display: inline-block;
    }

    hr {
      margin: 25px 0;
      border: 0.5px dashed black;
    }

    .buttons {
      padding-top: 30px;
    }

    .error {
      display: block;
      color: red;
      text-align: center;
      font-weight: bold;
    }

    .btn {
      font-size: 12px;
      display: inline-block;
      margin: 10px 0;
      padding: 5px 20px;
      border-radius: 10px;
      text-decoration: none;
    }

    .btn-cancel {
      border: none;
      color: white;
      background: #c4c4c4;
      margin-right: 10px;
    }

    .btn-submit {
      border: none;
      color: white;
      background: #96293b;
      padding: 5px 40px;
    }
  }
}

.comprobante {
  display: grid;

  .thankyou {
    text-align: center;
    justify-self: center;
    width: 250px;

    margin: 30px 0;

    h1 {
      color: #96293b;
      font-size: 40px;
    }

    h2 {
      color: #96293b;
      font-size: 25px;
    }

    p {
      color: black;
    }

    .comprobante-error {
      color: red;
      font-weight: bold;
      padding: 10px 0;
    }

    .spinner {
      img {
        width: 200px;
      }
    }

    .download {
      display: block;
      margin: 100px 0;
      text-decoration: none;
      img {
        width: 100px;
      }
    }
  }
}

.error-404 {
  display: grid;
  text-align: center;
  color: #96293b;

  h1 {
    font-size: 80px;
    margin-bottom: 0;
  }

  h4 {
    padding-bottom: 100px;
  }

  a {
    text-decoration: none;
    color: #96293b;
    margin-bottom: 100px;
    img {
      width: 150px;
      margin-bottom: 10px;
    }
    span {
      display: block;
    }
  }
}
